import Request from '../Request.utils'
export interface IListRequestQuery {
  isPrinted?: number;
  page?: number;
  readyForPrint?: number;
}
export interface IUpsertRequestBody {
  artworkUrls?: string[];
  clientId?: string;
  data?: any;
  designId?: string;
  id?: string;
  isPDFDownloaded?: boolean;
  isPrinted?: boolean;
  isRePrinted?: boolean;
  previewUrl?: string;
  productId?: string;
  productName?: string;
  quantity?: number;
}
export interface IRemoveRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IGetPrintJobByShopifyOrderIdRequestParams {
  orderId: string;
}


class PrintJobAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/print-jobs', 'GET', undefined, query, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/print-jobs', 'POST', undefined, undefined, body, );
    return res;
  }
  remove = async (params: IRemoveRequestParams) => {
    const res = await Request.call('/api/print-jobs/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/print-jobs/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  getPrintJobByShopifyOrderId = async (params: IGetPrintJobByShopifyOrderIdRequestParams) => {
    const res = await Request.call('/api/print-jobs/from-shopify-order/:orderId', 'GET', params, undefined, undefined, );
    return res;
  }
}
export default new PrintJobAPI()