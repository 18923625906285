import React, { useEffect, useRef, useState } from 'react';
import { IScreen } from 'type';
import { CMSLayout, Col, Row, Text, Button, useUIState, Grid, RatioCol, ShimmerLoading, useRefState, } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { TimeHelper } from 'helpers';
import Store from 'store';
import { Image } from 'react-native';

const ListDesigns: IScreen = () => {
  const { navigation } = useNavFunc();

  const UserStore = Store.useUserStore();
  const DesignStore = Store.useDesignStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [designs, getDesigns, setDesigns] = useRefState<Array<TDesign>>([])

  const [{ loading: isCreating}, setCreatingUI] = useUIState();
  const [{ loading: isCreatingOnDemand }, setCreatingOnDemandUI] = useUIState();

  const getData = async (p) => {
    try {
      const u = await UserStore.onReady();
      const resellerId = u?.role === 'admin' ? '' : u?.id;
      if (getDesigns().length === 0) {
        setUI({ fetching: true, errorMes: '' });
      }
      await TimeHelper.wait(500);
      const { list, hasNext, total, error } = await DesignStore.getList(p, resellerId);
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: false, errorMes: error });
      setPagination({ hasNext, total });
      setDesigns(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: false, errorMes: String(err) });
    }
  };

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    return unsubscribe;
  }, []);

  const renderDesigns = () => {
    return (
      <Grid xs='50%' md='25%' marginHorizontal={-30}>
          {designs.map((val, i) => {
            return (
              <Col paddingTop={40} bgWhite shadow overflow={'hidden'} round1 m1 p1 key={val.id} onPress={() => navigation.navigate(SCREEN.UpsertDesign, { id: val.id })}>
                <RatioCol
                  width={'100%'}
                  ratio={1}
                >
                  <Image source={{ uri: val.image }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
                </RatioCol>
                <Row height={50} mt1>
                  <Text numberOfLines={2} subtitle1>{val.name}</Text>
                </Row>
                <Text>SKU: <Text bold>{val.sku}</Text></Text>
              </Col>
            )
          })}
        </Grid>
    );
  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3 >Product Designs</Text>
        <Row>
          <Button
            text="Create"
            isLoading={isCreating}
            width={200} height={40} borderRadius={20}
            onPress={async () => {
              // navigation.navigate(SCREEN.UpsertDesign, { id: 'new' });
              setCreatingUI({ loading: true });
              const res = await Store.Api.Design.upsert({
                name: 'Untitled design',
                isCustomizable: true,
              });
              if (res.data.error) {
                setCreatingUI({ loading: false });
                return alert(res.data.error);
              } else if (res.data.data.id) {
                setCreatingUI({ loading: false });
                navigation.reset({
                  index: 0,
                  routes: [{name: SCREEN.UpsertDesign, params: { id: res.data.data.id }}],
                });
              }
            }}
          />
        </Row>
      </Row>
      <Col flex1 m2 marginTop={-5} p2>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <Row height={200} stretch marginHorizontal={-30}>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
            </Row>
          ) : (
            renderDesigns()
          )
        )}
        
      </Col>
    </CMSLayout>
  );
};

ListDesigns.routeInfo = {
  title: 'List Products - Designs - Macmillan',
  path: '/designs',
};

export default ListDesigns;