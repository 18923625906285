import React, { useState } from 'react';
import { Col, Text, TouchField } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR } from 'const';

const SideNav = ({ title, screen }) => {
  const { navigate, route, navigation } = useNavFunc();
  const [isHover, setIsHover] = useState(false);
  const isActive = route.name === screen;

  const bgColor = isActive ? COLOR.MAIN : (
    isHover ? COLOR.GREY_LIGHT : 'transparent'
  );
  const textColor = isActive ? 'white' : 'black';

  return (
    <Col
      round2 m1 mh2 p1 onPress={() => navigate(screen)}
      backgroundColor={bgColor}
      onMouseEnter={( ) => {
        setIsHover(true);
      }}
      onMouseLeave={( ) => {
        setIsHover(false);
      }}
    >
      <Text fontSize={14} bold={isActive} color={textColor}>{title}</Text>
    </Col>
  );
};

export default SideNav;
