import Request from '../Request.utils'
export interface IFromPolotnoRequestBody {
  json?: any;
}
export interface IUrlToBase64RequestBody {
  url?: string;
}


class ImageAPI {
  fromPolotno = async (body: IFromPolotnoRequestBody) => {
    const res = await Request.call('/api/images/from-polotno', 'POST', undefined, undefined, body, );
    return res;
  }
  urlToBase64 = async (body: IUrlToBase64RequestBody) => {
    const res = await Request.call('/api/images/url-to-base64', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new ImageAPI()