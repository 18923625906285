import Request from '../Request.utils'
export interface IFromImageRequestBody {
  images?: string[];
}
export interface IFromPrintJobRequestBody {
  id?: string;
  images: string[];
}


class PdfAPI {
  fromImage = async (body: IFromImageRequestBody) => {
    const res = await Request.call('/api/pdf/from-image', 'POST', undefined, undefined, body, );
    return res;
  }
  fromPrintJob = async (body: IFromPrintJobRequestBody) => {
    const res = await Request.call('/api/pdf/from-print-job', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new PdfAPI()