import React from 'react';
import SelectWeb, { components, Props as SelectProps } from 'react-select';
import { Col, IColProps } from 'components';
import { COLOR } from 'const';
import AsyncSelect, { useAsync } from 'react-select/async';

interface ISelectProps extends IColProps {
  required?: boolean,
  value: any,
  onChange: any,
  options?: Array<any>,
  styles?: any,
  noBorder?: boolean,
  isMulti?: boolean,
  innerProps?: SelectProps,
  loadOptions?: any,
  defaultOptions?: Array<any>,
  [additionProp: string]: any,
}

const Select = ({ value, onChange, options, styles, noBorder, isMulti, innerProps, loadOptions, defaultOptions, ...props }: ISelectProps) => {
  const height =  props.height ? { height: props.height, minHeight: props.height } : {};
  const SelectComponent = !!loadOptions ? AsyncSelect : SelectWeb;
  const handleInputChange = !loadOptions ? undefined : (newValue) => {

  };
  return (
    <Col {...props}>
      <SelectComponent
        value={value}
        onChange={onChange}
        options={options}
        isMulti={isMulti}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        styles={{
          control: (style) => ({
            ...style,
            height: isMulti ? 'auto' : 40,
            minHeight: 40,
            borderRadius: 8,
            backgroundColor: 'transparent',
            borderColor: noBorder ? 'transparent' : COLOR.BORDER,
            boxShadow: 'none',
            '&:hover': {
              borderColor: noBorder ? 'transparent' : COLOR.BORDER,
            },
            ...height,
          }),
          input: styles => ({ ...styles, outline: 'none', ...height, }),
          indicatorSeparator: (style) => ({ display: 'none' }),
          placeholder: (style) => ({
            ...style,
            fontSize: 14,
            color: COLOR.FONT,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            ...height,
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            ...height,
          }),
          ...props.styles,
        }}
        menuPortalTarget={document.querySelector('body')}
        {...innerProps}
      />
    </Col>
  )
}

export const Select01 = (props : ISelectProps) => {
  return (
    <Select
      noBorder
      backgroundColor={COLOR.GREY_LIGHT}
      borderRadius={4}
      {...props}
    />
  )
}

export default Select;