import Client from "./index";
class Editor {
  client: typeof Client;

  constructor(thisClient: typeof Client) {
    this.client = thisClient;
  }

  element: HTMLIFrameElement | null = null;
  didIframeLoad = false;

  init = (elementId: string) => {
    if (typeof window === "undefined") return;
    this.element = document.querySelector("#" + elementId);
    if (!this.element) throw new Error(`Iframe ${elementId} not found `);
    this.element.onload = this.initOnLoad;
  };

  initOnLoad = (event: any) => {
    this.element = event.target;
    if (!this.element) throw new Error(`Iframe not found `);
    const iframeUrl = new URL(this.element.getAttribute("src") as string);
    console.log("iframeUrl", iframeUrl);
    const sendToken = () => {
      this.element?.contentWindow?.postMessage(
        `token=${this.client.getToken()}`,
        "*"
      );
    };
    function handlePingAndAuthenticate(event: any) {
      if (event.origin !== iframeUrl.origin) return;
      if (event.data === "ping from editor") {
        sendToken();
      }
      window.removeEventListener("message", handlePingAndAuthenticate);
    }
    window.addEventListener("message", handlePingAndAuthenticate);
    sendToken();
  };

  initOnLoadAndOpenCommunication = (fn : any) => (event: any) => {
    this.element = event.target;
    if (!this.element) throw new Error(`Iframe not found `);
    const iframeUrl = new URL(this.element.getAttribute("src") as string);
    console.log("iframeUrl", iframeUrl);
    const sendToken = () => {
      this.element?.contentWindow?.postMessage(
        `token=${this.client.getToken()}`,
        "*"
      );
    };
    function handlePingAndAuthenticate(event: any) {
      if (event.origin !== iframeUrl.origin) return;
      if (event.data === "ping from editor") {
        sendToken();
      } else {
        typeof fn === 'function' && fn(event.data);
      }
      // window.removeEventListener("message", handlePingAndAuthenticate);
    }
    window.addEventListener("message", handlePingAndAuthenticate);
    sendToken();
  };

  authorizeParent = (origin = "*", callback?: () => void) => {
    if (typeof window === "undefined") return;
    const setToken = (t: string) => {
      this.client?.setToken(t);
    };
    window.addEventListener("message", function (event) {
      if (window.location !== window.parent.location) {
        console.log("Editor window.addEventListener", event);
      }
      if (origin !== "*" && event.origin !== origin) return;
      if (typeof event.data === "string" && event.data.startsWith("token=")) {
        setToken(event.data.replace("token=", ""));
        typeof callback === "function" && callback();
      }
    });
    window.parent.postMessage("ping from editor", "*");
  };

  sendToParent(data : { event: string, payload: any }) {
    window.parent.postMessage(data, "*");
  }
}

export default Editor;
