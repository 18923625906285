import create from 'zustand';
import { useEffect, useState, Dispatch } from 'react';
import { useUIState, IStateUI } from 'components';
import { TDesign } from 'type';
import Client from './api-client';
import Store from 'store';

interface IDesignStore {
  designs: {
    [designId: string]: TDesign,
  },
  getList(p: number, resellerId?: string): Promise<{ hasNext: boolean, list: Array<TDesign>, total: number, error?: string, }>,
  useDesign(id: string, useCache?: boolean) : {
    design: TDesign | undefined,
    uiState: IStateUI,
    setDesign: Dispatch<TDesign>,
  },
  [otherKey: string]: any,
}

export const useDesignStore = create<IDesignStore>((set, get) => ({
  designs: {},
  getList: async (page = 1, createdByUserId) => {
    const res = await Client.Api.Design.list({ page, createdByUserId });

    if (!res.data.success) {
      return {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      }
    }

    if (res.data.data.list && Array.isArray(res.data.data.list)) {
      const obj : IDesignStore['designs'] = {}
      res.data.data.list.forEach((j : TDesign) => {
        obj[j.id] = j;
      });
      set({ Designs: { ...get().jobs, ...obj } });
    }
    return res.data.data;
  },
  useDesign: (id : string, useCache = true) => {
    
    const [uiState, setUI]= useUIState();
    const [design, setDesign] = useState<TDesign>();

    useEffect(() => {
      if (id === 'new') return;
      if (useCache && !!get().designs[id]) {
        setDesign(get().Designs[id]);
        return;
      }
      (async () => {
        setUI({ fetching: true });
        try {
          const res = await Store.Api.Design.detail({ id });
          if (res.data.error) {
            setUI({ fetching: false, errorMes: res.data.error });
            return;
          }
          if (res.data.data) {
            setDesign(res.data.data);
            setUI({ fetching: false, errorMes: '' });
          }
        } catch(err) {
          setUI({ fetching: false, errorMes: String(err) });
        }
      })();
    }, [id, useCache]);

    return { design, setDesign, uiState };
  }
}));
