import { useEffect } from 'react';
import Client from './api-client';
import { useUserStore } from './User.Store';
import { usePrintJobStore } from './PrintJob.Store';
import { useDesignStore } from './Design.Store';

// default to 'http://localhost:3000'
Client.setHost('http://localhost:3001');
Client.setHost('https://macmillan.personify.tech');

if (process.env.NODE_ENV !== 'development') {
  Client.setHost('https://macmillan.personify.tech');
}

export const Store = {
  Client,
  Api: Client.Api,
  useUserStore,
  usePrintJobStore,
  useDesignStore,
}

export default Store;