import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, PrintHistoryItem, SwapIconEffect } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper } from 'helpers';
import { Entypo, Feather } from '@expo/vector-icons';

const PrintHistory: IScreen = () => {
  const { navigation } = useNavFunc();
  const PrintJobStore = Store.usePrintJobStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [jobs, setJobs] = useState<Array<TPrintJob>>([])

  const getData = async (p) => {
    try {
      setUI({ fetching: true, errorMes: '' });
      await TimeHelper.wait(300);
      const { list, hasNext, total, error } = await PrintJobStore.getList(p, true);
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: true, errorMes: error });
      setPagination({ hasNext, total });
      setJobs(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    return unsubscribe;
  }, []);

  const renderJobList = () => {
    if (jobs.length === 0) {
      return (
        <Col flex1>
          <Text>No jobs found</Text>
        </Col>
      )
    }
    return (
      <Col flex1>
        <Row
          height={50} stretch
          borderBottomColor={COLOR.GREY_BG}
          borderBottomWidth={1}
        >
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Product</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Preview</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>QTY</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Created At</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Time Since Order</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Production Notes</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Reprint</Text>
          </Col>
        </Row>
        <Col>
          {jobs.map((val, i) => {
            return (
              <PrintHistoryItem
                key={val.id}
                data={val}
                onDataUpdate={(newData) => {
                  const list = jobs.slice();
                  list[i] = newData;
                  setJobs(list);
                }}
              />
            )
          })}
        </Col>
      </Col>
    )
  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 justifyContent={'space-between'}>
        <Text h3 marginBottom={0}>Print History</Text>
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <Col>
              <Row height={50} stretch mt2>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
              <Row height={50} stretch mt2>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
            </Col>
          ) : (
            renderJobList()
          )
        )}
      </Col>
    </CMSLayout>
  );
};

PrintHistory.routeInfo = {
  title: 'Print History - Macmillan',
  path: '/print-history'
}

export default PrintHistory;
