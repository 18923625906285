import BottleGoose from './svg/BottleGoose';
import Eye from './svg/Eye';
import Note from './svg/Note';
import Pencil from './svg/Pencil';
import Connect from './svg/Connect';


export const SVG = {
  BottleGoose,
  Eye,
  Note,
  Pencil,
  Connect,
}

export const ASSETS = {
  SHOPIFY: require('./img/shopify.png'),
  WOOCOMMERCE: require('./img/woocommerce.png'),
  LOGO: require('./img/logo.png'),
  TAGS_GUIDELINE: require('./img/tags-guideline.png'),
}