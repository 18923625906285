import React, { useEffect, useState } from 'react';
import { TouchField, Col, Row, Text, SwapIconEffect, modal } from 'components';
import { TPrintJob, TUser } from 'type';
import { SVG } from 'assets';
import { SimpleLineIcons, Feather, MaterialIcons, AntDesign, EvilIcons, Ionicons } from '@expo/vector-icons';
import { TimeHelper } from 'helpers';
import { Image } from 'react-native';
import {
  ActivityIndicator
} from 'react-native';
import { COLOR } from 'const';
import Store from 'store';
import * as Animatable from 'react-native-animatable';
import NoteModal from './NoteModal';

interface IPrintJobItemProps {
  data: TPrintJob,
  onDownload: any,
  reseller?: TUser,
  onDataUpdate(newData: TPrintJob): void,
  onDelete: any,
}

const ICON = {
  DOWNLOAD: 0,
  LOADING: 1,
  DONE: 2
}

const PrintJobItem = ({ reseller, data, onDownload, onDataUpdate, onDelete }: IPrintJobItemProps) => {
  const val = data;
  const [actionIcon, setActionIcon] = useState<[number, 'rotate' | 'fade']>([ICON.DOWNLOAD, 'rotate']);
  const [key, setKey] = useState(Math.random());
  const [anim, setAnim] = useState({});
  const [showItem, setShowItem] = useState(true);

  useEffect(() => {
    if (data.isPrinted) {
      setAnim({
        animation: 'fadeOut',
        duration: 500,
        onAnimationEnd: () => {
          setShowItem(false);
        }
      })
    }
  }, [data.isPrinted]);

  const handleDownload = async () => {
    setActionIcon([ICON.LOADING, 'rotate']);
    if (typeof onDownload === 'function') {
      try {
        await TimeHelper.wait(300);
        await onDownload();
      } catch (err) {
        setActionIcon([ICON.DOWNLOAD, 'rotate']);
      }
    }
    setActionIcon([ICON.DONE, 'rotate']);
    await TimeHelper.wait(2000);
    setActionIcon([ICON.DOWNLOAD, 'fade']);
    onDataUpdate({
      ...val,
      isPDFDownloaded: true,
    });
  };

  const confirmPrinted = async () => {
    await Store.Api.PrintJob.upsert({
      id: val.id,
      isPrinted: true,
    })
    onDataUpdate({
      ...val,
      isPrinted: true,
    })
  }
  const cancelPrinted = async () => {
    await Store.Api.PrintJob.upsert({
      id: val.id,
      isPDFDownloaded: false,
      isPrinted: false,
    })
    onDataUpdate({
      ...val,
      isPDFDownloaded: false,
      isPrinted: false,
    })
  }

  const showPreview = () => {
    const image = (
      <Image
        style={{
          width: 300,
          height: 300,
        }}
        resizeMode='contain'
        source={{ uri: val.previewUrl }}
      />
    )
    modal.show(image);
  }

  const showNotes = () => {
    modal.show(<NoteModal printJobId={val.id} />);
  }

  const showInfo = (e) => {
    const nativeEvent = e.nativeEvent;
    modal.show(
      <Col shadow bgWhite p1 width={150} height={70} transform={[{ translateX: -75 }]}>
        <Text subtitle1 mb0>Artboard Size</Text>
        <Text>{(data.data?.product?.physicalWidth || 0).toFixed(2)} / {(data.data?.product?.physicalHeight || 0).toFixed(2)}</Text>
        <Col
          width={0}
          height={0}
          borderLeftWidth={11 / 2}
          borderLeftColor={'transparent'}
          borderRightWidth={11 / 2}
          borderRightColor={'transparent'}
          borderBottomWidth={11}
          borderBottomColor={'white'}
          absolute
          top={-11}
          left={75-11/2}
        />
      </Col>
      , {
        contentStyle: {
          position: 'absolute',
          top: nativeEvent.pageY + 15,
          left: nativeEvent.pageX,
        }
      })
  }

  return showItem ? (
    <Animatable.View {...anim} style={{ flexDirection: 'row', alignItems: 'center' }} key={val.id}>
      <Row flex1 m1>
        <Text>{val.productName}</Text>
        <TouchField cirle middle ml0 onPress={showInfo}>
          <Ionicons name="information-circle-outline" size={16} color={COLOR.FONT} />
        </TouchField>
      </Row>
      <Col flex1 m1>
        <TouchField cirle middle onPress={showPreview}>
          <SVG.Eye />
        </TouchField>
      </Col>
      <Col flex1 m1>
        <Text>{val.quantity}</Text>
      </Col>
      <Col flex1 m1>
        <Text>{TimeHelper.formatDay(val.createdAt)}</Text>
      </Col>
      <Col flex1 m1>
        <Text></Text>
      </Col>
      <Col flex1 m1>
        <TouchField cirle middle onPress={showNotes}>
          <SimpleLineIcons name="notebook" size={20} color="black" />
        </TouchField>
      </Col>
      <Col flex1 m1>

        {val.isPDFDownloaded ? (
          <Col margin={5}>
            <Text caption color={COLOR.GREY}>Have you printed?</Text>
            <Row>
              <TouchField width={20} height={20} borderRadius={10} middle onPress={confirmPrinted}>
                <MaterialIcons name="done" size={15} color={'black'} />
              </TouchField>
              <TouchField width={20} height={20} borderRadius={10} middle ml1 marginTop={3} onPress={cancelPrinted}>
                <AntDesign name="close" size={14} color="black" />
              </TouchField>
            </Row>
          </Col>

        ) : (
          <SwapIconEffect
            width={40}
            height={40}
            displayIndex={actionIcon[0]}
            key={key}
            effect={actionIcon[1] || 'rotate'}
          >
            <TouchField cirle middle onPress={handleDownload}>
              <Feather name="download" size={20} color="black" />
            </TouchField>
            <Col flex1 middle>
              <ActivityIndicator size="small" color="black" />
            </Col>
            <Col flex1 middle>
              <MaterialIcons name="done" size={20} color={COLOR.GREEN} />
            </Col>
          </SwapIconEffect>
        )}
      </Col>
      <Col width={50} m1>
        <TouchField cirle middle onPress={onDelete}>
          <EvilIcons name="trash" size={24} color={COLOR.FONT} />
        </TouchField>
      </Col>
    </Animatable.View>
  ) : null;
};

export default PrintJobItem;
