import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, TouchField, Input, Text, SwapIconEffect, modal, UseMemo } from 'components';
import { COLOR, SCREEN } from 'const';
import { SVG, ASSETS } from 'assets';
import { useDynamicResponsiveValue } from 'quickly-react';
import * as Animatable from 'react-native-animatable';
import { StyleSheet, ActivityIndicator, Animated, Image } from 'react-native';
import { MaterialCommunityIcons, Feather, Ionicons } from '@expo/vector-icons';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { adminNav, resellerNav } from './nav';
import SideNav from './SideNav';
import Drawer from './Drawer';
import { useExpansionAnim, animStill, AnimatableView } from './CMSLayout.anim';
import { Test } from './Test'

export const HEADER_LOGIN_WIDTH = 269 * 0.7;
export const HEADER_LOGIN_HEIGHT = 79 * 0.7;

const HeaderBar = Row;
const SideBar = Col;
const MainContent = Animated.View;

let didAnimatedOneTime = false;

interface ICMSLayoutProps {
  children: any,
  requireAuthen?: boolean,
  loginRedirectScreen?: string,
  expanded?: boolean,
}

const CMSMainContent = ({ children }: any) => {
  return !didAnimatedOneTime ? (
    <Animatable.View animation={'fadeIn'} duration={300} delay={300} style={{ flex: 1 }}>
      {children}
    </Animatable.View>
  ) : (
    <Col flex1>
      {children}
    </Col>
  )
}

const CMSLayout = ({ children, requireAuthen, loginRedirectScreen, expanded }: ICMSLayoutProps) => {
  const { navigate } = useNavFunc();
  const UserStore = Store.useUserStore();
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });
  const [mobileLeftIconIndex, setMobileLeftIconIndex] = useState(0);

  const { shouldDoExpansionAnim, headerAnimated, mainAnimated } = useExpansionAnim(expanded);

  useEffect(() => {
    if (requireAuthen) {
      UserStore.onReady().then((u) => {
        if (!u) {
          const params = loginRedirectScreen ? { redirect: loginRedirectScreen } : undefined;
          navigate(SCREEN.Login, params);
        }
      });
    }
  }, [requireAuthen, loginRedirectScreen]);

  const onPressLogout = () => {
    Store.Api.User.logout();
    UserStore.set({ user: null, token: '' });
    navigate(SCREEN.Login);
  };

  const renderSidebarContainer = () => {
    if (breakpoint === 'xs') {
      return null;
    }
    if (shouldDoExpansionAnim || !didAnimatedOneTime) {

      const animation = !didAnimatedOneTime ? 'fadeIn' : shouldDoExpansionAnim ?
        (expanded ? "fadeOutLeftBig" : "fadeInLeftBig") : undefined;

      return (
        <AnimatableView
          firstRenderAnim={!didAnimatedOneTime ? 'fadeIn' : animStill}
          style={styles.sidebarDesktop}
          animation={animation}
          duration={300}
          onAnimationEnd={() => {
            didAnimatedOneTime = true;
          }}
        >
          {renderSidebar()}
        </AnimatableView>
      )
    }
    return (
      <SideBar style={styles.sidebarDesktop}>
        {renderSidebar()}
      </SideBar>
    );
  };

  const renderSidebar = () => {
    const isReseller = UserStore.user?.role === 'reseller';
    const nav = isReseller ? resellerNav : adminNav;

    return (
      <>
        {nav.map((val, i) => (
          <SideNav key={val.title} title={val.title} screen={val.screen} />
        ))}
      </>
    );
  };

  const openMobileDrawer = () => {
    setMobileLeftIconIndex(1);
  };

  const closeMobileDrawer = () => {
    setMobileLeftIconIndex(0);
  };

  const renderHeader = () => {
    const userName = UserStore.user?.firstName ? `${UserStore.user?.firstName} ${UserStore.user?.lastName}` : ''
    return (
      <Animated.View
        style={{
          backgroundColor: 'white',
          flexDirection: 'row',
          alignItems: 'stretch',
          height: HEADER_LOGIN_HEIGHT,
          transform: [
            { translateY: headerAnimated.containerY },
          ]
        }}
      >
        {breakpoint === 'xs' ? (
          <Row stretch width100p height={HEADER_LOGIN_HEIGHT} backgroundColor={COLOR.MAIN}>
            <SwapIconEffect
              width={50} height={55}
              displayIndex={mobileLeftIconIndex}
              effect={mobileLeftIconIndex === 0 ? 'rotate' : 'rotate-backward'}
            >
              <TouchField width={50} height={55} middle onPress={openMobileDrawer}>
                <Ionicons name="menu-sharp" size={24} color="white" />
              </TouchField>
              <TouchField width={50} height={55} middle onPress={closeMobileDrawer}>
                <Ionicons name="close" size={24} color="white" />
              </TouchField>
            </SwapIconEffect>
            <Col flex1 middle>
              {/* <SVG.BottleGoose
                fill='white'
                width={'25%'}
              /> */}
              <Image
                source={ASSETS.LOGO}
                style={{ width: 150, height: 96/3 }}
              />
            </Col>
            <TouchField width={50} middle onPress={onPressLogout}>
              <MaterialCommunityIcons name="logout" size={24} color="white" />
            </TouchField>
          </Row>
        ) : (
          <>
            <Animated.View
              style={{
                transform: [
                  { translateX: headerAnimated.logoXY.x },
                  { translateY: headerAnimated.logoXY.y },
                ]
              }}
            >
              <Col
                onPress={() => navigate(SCREEN.Home)}
                middle width={HEADER_LOGIN_WIDTH}
                height={HEADER_LOGIN_HEIGHT}
                backgroundColor={COLOR.MAIN}
              >
                {/* <SVG.BottleGoose
                  fill='white'
                  width={'56.25%'}
                /> */}
                <Image
                  source={ASSETS.LOGO}
                  style={{ width: 150, height: 96/3 }}
                />
              </Col>
            </Animated.View>

            <HeaderBar flex1 justifyContent={'space-between'}>
              <Row ml2 width={200} borderRadius={15} backgroundColor={COLOR.GREY_LIGHT}>
                <Input
                  height={30}
                  borderColor='transparent'
                  inputProps={{
                    style: {
                      // @ts-ignore
                      outline: 'none',
                      fontSize: 12,
                    },
                    placeholderTextColor: COLOR.GREY,
                  }}
                  placeholder='Search'
                />
                <Col absolute top={3} right={10}>
                  <Feather name="search" size={20} color={COLOR.GREY} />
                </Col>
              </Row>
              <Row mr1>
                <Text body2 mr0>{userName}</Text>
                <TouchField cirle width={40} height={40} middle onPress={onPressLogout}>
                  <MaterialCommunityIcons name="logout" size={24} color={COLOR.GREY} />
                </TouchField>
              </Row>

            </HeaderBar>
          </>
        )}

      </Animated.View>
    );
  };

  return (requireAuthen && !UserStore.user) ? (
    <Col flex1 middle>
      <ActivityIndicator color="black" size="large" />
      <Text mt2>Please wait...</Text>
    </Col>
  ) : (
    <Col flex1 backgroundColor={COLOR.GREY_BG}>
      {renderHeader()}
      <Row stretch flex1>
        {renderSidebarContainer()}
        <MainContent
          style={shouldDoExpansionAnim ? {
            position: 'absolute',
            left: mainAnimated.left,
            top: mainAnimated.top,
            right: 0,
            bottom: 0,
          } : {
            flex: 1,
            height: '100%',
          }}
        >
          <CMSMainContent>
            {children}
          </CMSMainContent>
        </MainContent>
      </Row>
      {/* {(mobileLeftIconIndex === 1) && (
        
      )} */}
      <Drawer
        navigate={navigate}
        show={mobileLeftIconIndex === 1}
      />
    </Col>
  );
};

const styles = StyleSheet.create({
  sidebarDesktop: {
    width: HEADER_LOGIN_WIDTH,
    height: '100%',
    backgroundColor: 'white',
    paddingTop: 60,
    // @ts-ignore
    overflowY: 'auto',
  }
})

export default CMSLayout;