import Request from '../Request.utils'
export interface IShopifyWebhookRequestParams {
  id?: string;
}


class ShopAPI {
  shopifyWebhook = async (params: IShopifyWebhookRequestParams) => {
    const res = await Request.call('/api/online-stores/:id/shopify-webhook', 'POST', params, undefined, undefined, );
    return res;
  }
  hpWebhook = async () => {
    const res = await Request.call('/api/online-stores/hp-webhook', 'POST', undefined, undefined, undefined, );
    return res;
  }
}
export default new ShopAPI()