export * from './screens';

export const FONT = {
  defaultFont: 'Raleway, sans-serif',
  bold: 'Raleway, sans-serif',
  light: 'Raleway, sans-serif',
};

export const COLOR = {
  FONT: 'black',
  MAIN: '#008B26',
  BORDER: '#707070',
  BLUE: '#005EB8',
  YELLOW: '#FFF100',
  GREY_BG: '#F5F5F5',
  GREEN: '#49D009',
  GREY: '#B9B9B9',
  GREY_LIGHT: '#F0F4F6',
}

export const INCH_TO_MM = 25.4;