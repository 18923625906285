import React from 'react';
import Navigation from './src/navigation/Navigation';
import { ModalContainer } from './src/components';
import './App.css';

function AppWeb() {
  return (
    <ModalContainer>
      <Navigation />
    </ModalContainer>
  );
}

export default AppWeb;