import React, { useState, useRef, useEffect } from 'react';
import { Col, IColProps } from 'components';
import { Text, Row } from 'components';
import { COLOR } from 'const';
import {
  ActivityIndicator,
} from 'react-native';
import { LightenDarkenColor } from 'lighten-darken-color';
import TouchField from './TouchField';

interface IButtonProps extends IColProps {
  mainColor?: string;
  outline?: boolean;
  text: string;
  iconLeft?: any,
  iconRight?: any,
  isLoading?: boolean,
  bgHovered?: string,
}

const Button = ({ text, mainColor, outline, iconLeft, iconRight, isLoading, bgHovered, ...props }: IButtonProps) => {
  const btnColor = mainColor || COLOR.MAIN;
  const backgroundColor = outline ? 'transparent' : btnColor;
  const textColor = !outline ? 'white' : btnColor;
  const borderColor = !outline ? 'transparent' : btnColor;
  return (
    <TouchField
      height={40}
      width={150}
      borderColor={borderColor}
      borderWidth={2}
      borderRadius={4}
      backgroundColor={backgroundColor}
      bgHovered={bgHovered || LightenDarkenColor(backgroundColor, 20)}
      {...props}
    >
      <Row flex1 middle>
        {isLoading ? (
          <ActivityIndicator
            size={"small"}
            color={outline ? COLOR.MAIN : 'white'}
            style={{ marginRight: 5 }}
          />
        ) : iconLeft}
        <Text color={textColor} center numberOfLines={2} marginLeft={!!iconLeft ? 5 : undefined}>
          {text}
        </Text>
        {iconRight}
      </Row>
    </TouchField>
  );
};

Button.colors = {
  redSolid: {
    backgroundColor: 'red',
    bgHovered: 'rgba(255,255,255,0.2)',
  },
}

export default Button;
