import React, { useMemo, useEffect, useState } from 'react';
import { Col } from 'components';
import { StyleSheet } from 'react-native';
import * as Animatable from 'react-native-animatable';

class ModalController {

  show(component, modalSettings = {}) {

  }

  hide() {

  }
}

export const modal = new ModalController();

export const ModalContainer = ({ children }) => {
  const [show, setShow] = useState(false);
  const [anim, setAnim] = useState({
    animation: 'fadeInUp',
    duration: 300,
    delay: 300,
  });
  const [comp, setComp] = useState(null);
  const [modalSettings, setModalSettings] = useState<any>({
    containerStyle: {},
    contentStyle: {},
    containerOnClick: () => setShow(false),
  });

  useEffect(() => {
    modal.hide = () => {
      setShow(false);
    };
    modal.show = (component, settings = {}) => {
      if (Object.keys(modalSettings).length > 0) {
        setModalSettings({
          ...modalSettings,
          ...settings,
        })
      }
      setComp(component)
      setShow(true);
    }
  });

  useEffect(() => {
    if (!show) setComp(null);
  }, [show])

  console.log('modalSettings', modalSettings);

  return (
    <Col flex1>
      {useMemo(() => children, [])}
      {show && (
        <Animatable.View
          style={{
            ...StyleSheet.absoluteFillObject,
            backgroundColor: 'rgba(0,0,0,0.3)',
          }}
          animation='fadeIn'
          duration={300}
        >
          <Col flex1 middle style={modalSettings.containerStyle} onPress={modalSettings.containerOnClick}>
            <Animatable.View {...anim} style={modalSettings.contentStyle}>
              <Col onPress={() => {}} activeOpacity={1}>
                {comp}
              </Col>
            </Animatable.View>
          </Col>
        </Animatable.View>
      )}
    </Col>
  );
};