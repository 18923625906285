import { SCREEN } from 'const';

export const adminNav = [
  // { title: 'Job Bag', screen: SCREEN.ListPrintJobs },
  // { title: 'Print History', screen: SCREEN.PrintHistory },
  // { title: 'Reporting', screen: SCREEN.Reports },
  // { title: 'Product Library', screen: SCREEN.ListProducts },
  { title: 'Data from HP', screen: SCREEN.HPData },
  { title: 'Product Designs', screen: SCREEN.ListDesigns },
  // { title: 'Resellers', screen: SCREEN.ListResellers },
];

export const resellerNav = [
  // { title: 'Product Library', screen: SCREEN.ListProducts },
  // { title: 'Product Designs', screen: SCREEN.ListDesigns },
  // { title: 'My Stores', screen: SCREEN.ListStores },
];