import { Col, Text, CMSLayout } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

const Reports : IScreen = ( ) => {
  const { navigate } = useNavFunc();
  // const UserStore = Store.useUserStore();

  return (
    <CMSLayout>
      <Col flex1 m2 p2 round1 bgWhite>
        <Text>Print Reports</Text>
      </Col>
    </CMSLayout>
  )
};

Reports.routeInfo = {
  title: 'Macmillan - Print Reports',
  path: '/reports',
};

export default Reports;